import React, { useEffect } from 'react';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, LineElement, PointElement, Title, Tooltip, Legend, ChartData, ChartOptions } from 'chart.js/auto';
import { Bar } from 'react-chartjs-2';
import { useState, useRef } from 'react';
import { useTheme } from '../../context/ThemeContext';
import tradePromotionApi from '../../api/service/TradePromotionService';
import { useQuery } from 'react-query';
import { useProvinces } from '../../context/ProvinceContext';

ChartJS.register(CategoryScale, LinearScale, BarElement, LineElement, PointElement, Title, Tooltip, Legend);

const dataDummy: ChartData<'bar' | 'line'> = {
  labels: [],
  datasets: [
    {
      label: 'Doanh thu sản phẩm địa phương',
      data: [
        1200 + Math.floor(Math.random() * 300) + 1,
        1500 + Math.floor(Math.random() * 300) + 1,
        1700 + Math.floor(Math.random() * 300) + 1,
        1600 + Math.floor(Math.random() * 300) + 1,
        1800 + Math.floor(Math.random() * 300) + 1,
        2000 + Math.floor(Math.random() * 300) + 1,
        2100 + Math.floor(Math.random() * 300) + 1,
        2300 + Math.floor(Math.random() * 300) + 1,
        2500 + Math.floor(Math.random() * 300) + 1,
        2200 + Math.floor(Math.random() * 300) + 1,
        1900 + Math.floor(Math.random() * 300) + 1,
        2400 + Math.floor(Math.random() * 300) + 1
      ],
      backgroundColor: 'rgba(255, 99, 132, 0.2)',
      borderColor: 'rgba(255, 99, 132, 1)',
      borderWidth: 1,
      type: 'bar' as const,
      yAxisID: 'y1',
    },
    {
      label: 'Doanh thu sản phẩm ngoài địa phương',
      data: [
        800 + Math.floor(Math.random() * 300) + 1,
        900 + Math.floor(Math.random() * 300) + 1,
        1000 + Math.floor(Math.random() * 300) + 1,
        950 + Math.floor(Math.random() * 300) + 1,
        1100 + Math.floor(Math.random() * 300) + 1,
        1300 + Math.floor(Math.random() * 300) + 1,
        1400 + Math.floor(Math.random() * 300) + 1,
        1600 + Math.floor(Math.random() * 300) + 1,
        1700 + Math.floor(Math.random() * 300) + 1,
        1500 + Math.floor(Math.random() * 300) + 1,
        1400 + Math.floor(Math.random() * 300) + 1,
        1700 + Math.floor(Math.random() * 300) + 1
      ],
      backgroundColor: 'rgba(75, 192, 192, 0.2)',
      borderColor: 'rgba(75, 192, 192, 1)',
      borderWidth: 1,
      type: 'bar' as const,
      yAxisID: 'y1',
    },
    // {
    //   label: 'Doanh thu sản phẩm quốc tế',
    //   data: [500, 600, 700, 650, 800, 900, 950, 1000, 1100, 950, 850, 1100],
    //   backgroundColor: 'rgba(75, 192, 192, 0.2)',
    //   borderColor: 'rgba(75, 192, 192, 1)',
    //   borderWidth: 1,
    //   type: 'bar' as const,
    //   yAxisID: 'y1',
    // },
    {
      label: 'Tỷ lệ phần trăm sản phẩm địa phương',
      data: [30 + Math.floor(Math.random() * 5) + 1,
        40 + Math.floor(Math.random() * 5) + 1,
        35 + Math.floor(Math.random() * 5) + 1,
        50 + Math.floor(Math.random() * 5) + 1,
        60 + Math.floor(Math.random() * 5) + 1,
        45 + Math.floor(Math.random() * 5) + 1,
        55 + Math.floor(Math.random() * 5) + 1,
        70 + Math.floor(Math.random() * 5) + 1,
        65 + Math.floor(Math.random() * 5) + 1,
        50 + Math.floor(Math.random() * 5) + 1,
        40 + Math.floor(Math.random() * 5) + 1,
        60 + Math.floor(Math.random() * 5) + 1],
      type: 'line' as const,
      borderColor: 'rgba(255, 159, 64, 1)',
      backgroundColor: 'rgba(255, 159, 64, 0.2)',
      borderWidth: 2,
      tension: 0.1,
      yAxisID: 'y2',
    },
    {
      label: 'Tỷ lệ phần trăm sản phẩm địa phương',
      data: [20 + Math.floor(Math.random() * 5) + 1,
        30 + Math.floor(Math.random() * 5) + 1,
        25 + Math.floor(Math.random() * 5) + 1,
        40 + Math.floor(Math.random() * 5) + 1,
        45 + Math.floor(Math.random() * 5) + 1,
        35 + Math.floor(Math.random() * 5) + 1,
        50 + Math.floor(Math.random() * 5) + 1,
        60 + Math.floor(Math.random() * 5) + 1,
        55 + Math.floor(Math.random() * 5) + 1,
        40 + Math.floor(Math.random() * 5) + 1,
        30 + Math.floor(Math.random() * 5) + 1,
        50 + Math.floor(Math.random() * 5) + 1],
      type: 'line' as const,
      borderColor: 'rgba(75, 192, 192, 1)',
      backgroundColor: 'rgba(75, 192, 192, 0.2)',
      borderWidth: 2,
      tension: 0.1,
      yAxisID: 'y2',
    },
    // {
    //   label: 'Tỷ lệ phần trăm sản phẩm quốc tế',
    //   data: [10, 20, 15, 25, 35, 30, 40, 50, 45, 30, 20, 40],
    //   type: 'line' as const,
    //   borderColor: 'rgba(75, 192, 192, 1)',
    //   backgroundColor: 'rgba(75, 192, 192, 0.2)',
    //   borderWidth: 2,
    //   tension: 0.1,
    //   yAxisID: 'y2',
    // },
  ],
};

const options: ChartOptions<'bar' | 'line'> = {
  maintainAspectRatio: false,
  plugins: {
    legend: {
      position: 'top',
      labels: {
        padding: 10,
      },
    },
  },
  scales: {
    x: {
      stacked: true,
    },
    y1: {
      type: 'linear',
      position: 'left',
      beginAtZero: true,
      title: {
        display: true,
        text: 'Triệu Đồng',
      },
    },
    y2: {
      type: 'linear',
      position: 'right',
      beginAtZero: true,
      suggestedMax: 100,
      display: false,
      title: {
        display: false,
        text: '',
      },
      grid: {
        drawOnChartArea: false,
      },
    },
  },
};

const RevenueChart: React.FC = () => {
  const { theme } = useTheme();
  const { currentProvince } = useProvinces();
  const [dataChart, setDataChart] = useState<any>();
  const chartContainerRef = useRef<HTMLDivElement>(null);
  const [labels , setLabels] = useState<any>()
  const [dataSet, setDataSet] = useState<any>();
  const [dateCreated, setDateCreated] = useState<string>('')
  const [internalCombinedArray, setInternalCombinedArray] = useState<any>()

  function getLast12Hours() {
    const now = new Date();
    const hoursArray = [];
  
    for (let i = 0; i < 12; i++) {
      const pastHour = new Date(now.getTime() - i * 60 * 60 * 1000);
      pastHour.setMinutes(0);
      pastHour.setSeconds(0);
      pastHour.setMilliseconds(0);
      
      hoursArray.push(pastHour.toLocaleTimeString('en-US', { hour12: false }));
    }

    dataDummy.labels = hoursArray
  }

  useEffect(() => {
    getLast12Hours()
  }, [])

  
  function getTwelveMonthsAgoISO() {
    const now = new Date();
    const twelveMonthsAgo = new Date(now);
    twelveMonthsAgo.setMonth(now.getMonth() - 12, 1);
    twelveMonthsAgo.setUTCHours(0, 0, 0, 0);
    const isoString = twelveMonthsAgo.toISOString();
    setDateCreated(isoString);
  }

  function getLast12Months() {
    const monthsArray: string[] = [];
    const now = new Date();
    for (let i = 0; i < 12; i++) {
      const monthDate = new Date(now.getFullYear(), now.getMonth() - i, 1);
      const year = monthDate.getFullYear();
      const month = (monthDate.getMonth() + 1).toString().padStart(2, '0');
      const formattedMonth = `${year}-${month}`;
      monthsArray.push(formattedMonth);
    }

    setLabels(monthsArray.reverse());
  }

  useEffect(() => {
    getLast12Months()
    getTwelveMonthsAgoISO();
  }, [])

  const { data: internalData } = useQuery(
    ['getRevenueIntervalStats', currentProvince?.id],
    () => tradePromotionApi.getRevenueIntervalStats({
      type: 'monthly',
      provinceId: currentProvince?.id,
      createdAtFrom: dateCreated
    }),
    {
      onSuccess: (response: any) => {
        const combinedArray = labels.map((label: any) => {
          const match = response?.revenueStats.find((item: any) => item?.blockStartTime === label);
          return match ? match.vndRevenue.toString() : '0';
        });

        setInternalCombinedArray(combinedArray)
        
        const dataSetsData = {
          label: 'Doanh thu sản phẩm địa phương',
          data: combinedArray,
          backgroundColor: 'rgba(255, 99, 132, 0.2)',
          borderColor: 'rgba(255, 99, 132, 1)',
          borderWidth: 1,
          type: 'bar' as const,
          yAxisID: 'y1',
        }

        setDataSet([dataSetsData])
      },
      enabled: Boolean(currentProvince?.id) && Boolean(dateCreated),
    }
  );

  function calculatePercentages(array1: any, array2: any): { percentArray1: any, percentArray2: any } {
    const totalSum = array1.concat(array2).reduce((acc: any, val: any) => Number(acc) + Number(val), 0);
    const percentArray1 = array1.map((val: any) => ((val / totalSum) * 100).toFixed(0) + '%');
    const percentArray2 = array2.map((val: any) => ((val / totalSum) * 100).toFixed(0) + '%');
  
    return { percentArray1, percentArray2 };
  }

  const { data: externalData } = useQuery(
    ['getRevenueExternalStats', currentProvince?.id],
    () => tradePromotionApi.getRevenueExternalStats({
      type: 'monthly',
      excludeProvinceId: currentProvince?.id,
      createdAtFrom: dateCreated
    }),
    {
      onSuccess: (response: any) => {
        const combinedArray = labels.map((label: any) => {
          const match = response?.revenueStats.find((item: any) => item?.blockStartTime === label);
          return match ? match.vndRevenue.toString() : '0';
        });

        const { percentArray1, percentArray2 } = calculatePercentages(internalCombinedArray, combinedArray);
        const dataSetsData = [
          {
            label: 'Doanh thu sản phẩm ngoài địa phương',
            data: combinedArray,
            backgroundColor: 'rgba(54, 162, 235, 0.2)',
            borderColor: 'rgba(54, 162, 235, 1)',
            borderWidth: 1,
            type: 'bar' as const,
            yAxisID: 'y1',
          },
          {
            label: 'Tỷ lệ phần trăm sản phẩm địa phương',
            data: percentArray1,
            type: 'line' as const,
            borderColor: 'rgba(255, 159, 64, 1)',
            backgroundColor: 'rgba(255, 159, 64, 0.2)',
            borderWidth: 2,
            tension: 0.1,
            yAxisID: 'y2',
          },
          {
            label: 'Tỷ lệ phần trăm sản phẩm địa phương',
            data: percentArray2,
            type: 'line' as const,
            borderColor: 'rgba(153, 102, 255, 1)',
            backgroundColor: 'rgba(153, 102, 255, 0.2)',
            borderWidth: 2,
            tension: 0.1,
            yAxisID: 'y2',
          },
        ]

        setDataSet([...dataSet, ...dataSetsData])
      },
      enabled: Boolean(internalData),
    }
  );

  useEffect(() => {
    if (externalData) {
      const data: ChartData<'bar' | 'line'> = {
        labels,
        datasets: dataSet
      }
      setDataChart(data)
    }
  }, [dataSet, externalData])
  

  return (
    <div
      className={`chart-container h-[91%] bg-white pt-5 dark:bg-[#15223B]` }
      ref={chartContainerRef}
    >
      {dataChart && <Bar data={currentProvince?.id === 'BK' ? dataDummy : dataChart as any} options={options} className="!h-auto !w-full" />}
    </div>
  )
};

export default RevenueChart;
